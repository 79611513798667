import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import MobileHeader from './components/MobileHeader';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Login from './pages/Login';
import RecipeSuggestions from './pages/RecipeSuggestions';
import PasswordProtection from './components/PasswordProtection';
import Account from './pages/Account'; // Import Account page
import Contact from './pages/Contact';
import Faq from './pages/Faq';
import KitchenTips from './pages/KitchenTips';
import RecipesHistory from './pages/RecipesHistory';
import { AuthProvider } from './context/AuthContext';
import { v4 as uuidv4 } from 'uuid';

const App: React.FC = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    console.log('isLoggedIn state:', isLoggedIn);
  }, [isLoggedIn]);


  function getSessionUUID() {
    let sessionUUID = sessionStorage.getItem('session_uuid');
    if (!sessionUUID) {
      sessionUUID = uuidv4();
      sessionStorage.setItem('session_uuid', sessionUUID);
      console.log("Session UUID:", sessionUUID);
    }
    return sessionUUID;
  }
  
  const sessionUUID = getSessionUUID();

  const handleAuthentication = (password: string): boolean => {
    const correctPassword = 'yeschef';
    const sanitizedPassword = password
      .trim() // Remove leading and trailing spaces
      .toLowerCase() // Convert to lowercase
      .replace(/[^\w]/g, ''); // Remove spaces and punctuation

    const authenticated = sanitizedPassword === correctPassword;
    setIsAuthenticated(authenticated);
    return authenticated;
  };

  if (!isAuthenticated) {
    return <PasswordProtection onAuthenticate={handleAuthentication} />;
  }

  return (
    <AuthProvider>
      <div className="mesh-gradient-background"></div>
      <Router>
        <div className="App">
          <MobileHeader isLoggedIn={isLoggedIn} />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/recipes" element={<RecipeSuggestions />} />
            <Route path="/about" element={<About />} />
            <Route path="/your_recipes" element={<RecipesHistory sessionUUID={sessionUUID} />} />
            <Route path="/kitchen_tips" element={<KitchenTips />} />
            <Route path="/login" element={<Login setIsLoggedIn={setIsLoggedIn} />} />
            <Route path="/signup" element={<Login setIsLoggedIn={setIsLoggedIn} />} />
            <Route path="/account" element={<Account setIsLoggedIn={setIsLoggedIn} />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/faq" element={<Faq />} />
            {/* <Route path="/logout" element={<Logout setIsLoggedIn={setIsLoggedIn} />} /> */}
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
          <Footer />
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;