import React, { useState } from 'react';
import IngredientInput from '../components/IngredientInput';
import RecipeList from '../components/RecipeList';
import { getRecipeSuggestions, Recipe } from '../services/api';

const RecipeSuggestions: React.FC = () => {
  const [recipes, setRecipes] = useState<Recipe[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [apiCalled, setApiCalled] = useState<boolean>(false);
  const [apiSuccess, setApiSuccess] = useState<boolean>(false);

  const handleIngredientSubmit = async (ingredients: string[], cuisine: string, dietaryRestrictions: string[]) => {
    console.log("Submitting ingredients:", ingredients);
    setLoading(true);
    setError(null);
    setApiCalled(true);
  
    // Map dietaryRestrictions to boolean flags
    const isVegan = dietaryRestrictions.includes('Vegan'); // Updated to match the string 'Vegan'
    const isVegetarian = dietaryRestrictions.includes('Vegetarian'); // Updated to match the string 'Vegetarian'
    const isGlutenFree = dietaryRestrictions.includes('Gluten-Free'); // Updated to match the string 'Gluten-Free'
    const isDairyFree = dietaryRestrictions.includes('Dairy-Free'); // Updated to match the string 'Dairy-Free'
  
  
    try {
      console.log("Calling getRecipeSuggestions");
      const fetchedRecipes = await getRecipeSuggestions({
        ingredients,
        cuisine,
        is_vegan: isVegan,
        is_vegetarian: isVegetarian,
        is_gluten_free: isGlutenFree,
        is_dairy_free: isDairyFree,
      });
      console.log("Received recipes:", fetchedRecipes);
      setRecipes(fetchedRecipes);
      setApiSuccess(true);
    } catch (err) {
      console.error('Error getting recipe suggestions:', err);
      setError('Error fetching recipes. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleBackClick = () => {
    setRecipes(null);
    setApiSuccess(false);
    setApiCalled(false);
  };

  const handleCloseLoading = () => {
    setLoading(false);
  };

  return (
    <div className="recipe-suggestions">
      <img src="/rat.svg" alt="Ratatouille NYC logo" className="logo-small"/>
      {!apiSuccess && (
        <div className="about-header">
          <h1 className="title">Find Recipes</h1>
          <p>Enter at least three ingredients below to get recipes with ingredients you already have on hand</p>
          <IngredientInput onSubmit={handleIngredientSubmit} />
        </div>
      )}
      {loading && (
        <div className="loading-overlay">
          <div className="loading-popup">
            <button className="close-button" onClick={handleCloseLoading}>×</button>
            <p>Loading recipes...</p>
            <video autoPlay loop muted playsInline style={{ maxWidth: '300px', width: '300px' }}>
              <source src="/rat_stir_looped.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div className="loading-text">
              <span className="fade-text">Coming up with recipe ideas</span>
              <span className="fade-text">Consulting with top chefs</span>
              <span className="fade-text">Translating your grandmother's cookbook</span>
            </div>
          </div>
        </div>
      )}
      {error && <div className="error">{error}</div>}
      {recipes && (
        <div>
          <RecipeList recipes={recipes} />
          <button onClick={handleBackClick} className="back-button">Back</button>
        </div>
      )}
    </div>
  );
};

export default RecipeSuggestions;