import React from 'react';
import { Link } from 'react-router-dom';

const Home: React.FC = () => {
  return (
    <div className="home-container">
      <div className="home-header">
        <img src="/rat.svg" alt="Ratatouille NYC logo" className="logo"/>
        <h1 className="title">Ratatouille NYC</h1>
      </div>
      <p className="description">Find recipes with what's in your fridge. Cook what you have on hand. Throw away less food. Save more.</p>
      <Link to="/recipes" className="link-white">
        <button className="recipe-button">
         Let's Cook
        </button>
      </Link>
    </div>
  );
};

export default Home;