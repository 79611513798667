import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface AccountProps {
  setIsLoggedIn: (loggedIn: boolean) => void;
}

const Account: React.FC<AccountProps> = ({ setIsLoggedIn }) => {
  const [showLogoutMessage, setShowLogoutMessage] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    setIsLoggedIn(false);
    setShowLogoutMessage(true);
    setTimeout(() => {
      setShowLogoutMessage(false);
      navigate('/');
    }, 2000); // Redirect after 2 seconds
  };

  return (
    <div>
        <div className="account-header">
          <img src="/rat.svg" alt="Ratatouille NYC logo" className="logo-small" />
          <h1 className="title">Ratatouille NYC</h1>
        </div>
        <div className="password-protection-container">
        <div className="password-protection-box">
          <h2>Account</h2>
          <p><strong>User Name:</strong> John Doe</p>
          <p><strong>Email:</strong> john_doe@foo.com</p>
          <button onClick={handleLogout}>Logout</button>
          {showLogoutMessage && (
            <div className="logout-message" style={{ color: 'green', marginTop: '10px' }}>
              You have been successfully logged out.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Account;