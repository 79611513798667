import React, { useEffect, useState } from 'react';
import RecipeList from '../components/RecipeList';
import { getSessionRecipes, Recipe } from '../services/api';

const RecipesHistory: React.FC<{ sessionUUID: string }> = ({ sessionUUID }) => {
  const [recipes, setRecipes] = useState<Recipe[] | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchRecipes = async () => {
      try {
        const fetchedRecipes = await getSessionRecipes(sessionUUID);
        setRecipes(fetchedRecipes);
      } catch (err) {
        console.error('Error fetching session recipes:', err);
        setError('Error fetching recipes. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchRecipes();
  }, [sessionUUID]);

  if (loading) {
    return (
        <div className="recipe-suggestions">
        <div className="recipe-list">
        <h1 className="title">Ratatouille NYC</h1>
        </div>
            <img src="/rat.svg" alt="Ratatouille NYC logo" className="logo-small"/>
            <div>Loading...</div>
        </div>
    );
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="recipe-suggestions">
      <img src="/rat.svg" alt="Ratatouille NYC logo" className="logo-small"/>
      {recipes ? <RecipeList recipes={recipes} /> : <div>No recipes found for this session.</div>}
    </div>
  );
};

export default RecipesHistory;