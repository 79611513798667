import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

// Create a configured axios instance
const api = axios.create({
  baseURL: API_BASE_URL,
  withCredentials: true, // This is important for CORS requests with credentials
  headers: {
    'Content-Type': 'application/json',
  },
});

export interface Recipe {
  meal: string;
  ingredients: string[];
  cuisine: string;
  is_vegan: boolean;
  is_vegetarian: boolean;
  is_gluten_free: boolean;
  is_dairy_free: boolean;
  calories: number;
  cook_time: number;
  instructions: string;
}

export interface AuthResponse {
  // access_token?: string;
  // token_type?: string;
  user_id?: string;
  user_name?: string;
  user_email?: string;
  authentication_status?: boolean;
  preferred_language?: string;
}

export const getRecipeSuggestions = async ({
  ingredients,
  cuisine,
  is_vegan,
  is_vegetarian,
  is_gluten_free,
  is_dairy_free
}: {
  ingredients: string[];
  cuisine?: string;
  is_vegan?: boolean;
  is_vegetarian?: boolean;
  is_gluten_free?: boolean;
  is_dairy_free?: boolean;
}): Promise<Recipe[]> => {
  const sessionUUID = sessionStorage.getItem('session_uuid') || 'default-uuid'; // Ensure sessionUUID is available

  console.log("API call: getRecipeSuggestions with data:", {
    ingredients,
    cuisine,
    is_vegan,
    is_vegetarian,
    is_gluten_free,
    is_dairy_free
  });

  try {
    const response = await api.post<Recipe[]>('/get_recipe_ideas', {
      ingredients,
      cuisine,
      is_vegan,
      is_vegetarian,
      is_gluten_free,
      is_dairy_free
    }, {
      headers: {
        'Session-UUID': sessionUUID // Include session_uuid in headers
      }
    });

    console.log("API response:", response.data);
    // Validate the response structure
    if (response.data && Array.isArray(response.data)) {
      return response.data;
    } else {
      throw new Error('Unexpected API response format');
    }
  } catch (error) {
    console.error("Error fetching recipe suggestions:", error);
    throw error;
  }
};

export const getSessionRecipes = async (sessionUUID: string): Promise<Recipe[]> => {
  try {
    const response = await api.get<Recipe[]>('/get_generated_recipes', {
      headers: {
        'Session-UUID': sessionUUID
      }
    });
    return response.data;
  } catch (error) {
    console.error("Error fetching session recipes:", error);
    throw error;
  }
};

export const loginUser = async (email: string, password: string): Promise<AuthResponse> => {
  try {
    const response = await api.post<AuthResponse>('/authenticate_user', {
      user_email: email,
      password: password
    });
    console.log(response.data)
    return response.data;
  } catch (error) {
    console.error("Error during login:", error);
    throw error;
  }
};

export const signupUser = async (email: string, password: string, user_name: string, language: string): Promise<AuthResponse> => {
  try {
    const response = await api.post<AuthResponse>('/signup', {
      user_email: email,
      password: password,
      user_name: user_name,
      preferred_language: language
    });
    return response.data;
  } catch (error) {
    console.error("Error during signup:", error);
    throw error;
  }
};

// ... You can add more API functions here as needed

export default api;