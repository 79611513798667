import React from 'react';

const Contact: React.FC = () => {
    return (
        <div className="contact-container">
            <div className="contact-header">
                <img src="/rat.svg" alt="Ratatouille NYC logo" className="logo-small" />
                <h1 className="title">Ratatouille NYC</h1>
                <div className="contact-meme-image">
                    <img src="/shawarma.png" alt="Chef Meme" className="logo" />
                    <p className="contact-description">We are a small team of engineers surviving on sourdough bread, internet chef memes and leftover meals. You can find us at <a href="mailto:hello@ratatouille.nyc">hello@ratatouille.nyc</a>.</p>
                </div>
            </div>
        </div>
    );
};

export default Contact;