import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { animated, useSpring } from 'react-spring';

interface MobileHeaderProps {
  isLoggedIn: boolean;
}

const MobileHeader: React.FC<MobileHeaderProps> = ({ isLoggedIn }) => {
  const [isOpen, setIsOpen] = useState(false);

  const closeMenu = () => {
    setIsOpen(false);
    setActiveSubMenu(null);
  };

  const [activeSubMenu, setActiveSubMenu] = useState<string | null>(null);

  const toggleSubMenu = (menuName: string) => {
    setActiveSubMenu(activeSubMenu === menuName ? null : menuName);
  };

  const { x } = useSpring({
    from: { x: 0 },
    to: { x: isOpen ? 1 : 0 },
  });

  const trans1 = x.to({
    range: [0, 1],
    output: [0, -45],
  }).to((x) => `rotate(${x}deg)`);

  const trans2 = x.to({
    range: [0, 1],
    output: [0, 45],
  }).to((x) => `rotate(${x}deg)`);

  const opacity = x.to({
    range: [0, 1],
    output: [1, 0],
  });

  const menuAnimation = useSpring({
    transform: isOpen ? 'translate3d(0,0,0)' : 'translate3d(-100%,0,0)',
    opacity: isOpen ? 1 : 0,
  });

  const headerAnimation = useSpring({
    backgroundColor: isOpen ? 'white' : 'transparent',
    width: isOpen ? '60%' : '0%',
  });

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const x1 = isOpen ? '0' : '20';
  const x2 = isOpen ? '60' : '80';
  const ya = isOpen ? '32' : '30';
  const yb = isOpen ? '72': '70';

  return (
    <animated.header className="mobile-header" style={headerAnimation}>
      <animated.button className="menu-button" onClick={toggleMenu}>
        <svg width="300" height="80" viewBox="0 0 100 100">
          <animated.line x1={x1} y1={ya} x2={x2} y2={ya} stroke="white" strokeWidth="8" style={{ transform: trans1, transformOrigin: '70% 20%' }} />
          <animated.line x1={x1} y1="50" x2={x2} y2="50" stroke="white" strokeWidth="8" style={{ opacity }} />
          <animated.line x1={x1} y1={yb} x2={x2} y2={yb} stroke="white" strokeWidth="8" style={{ transform: trans2, transformOrigin: '60% 90%' }} />
        </svg>
        </animated.button>
      {isOpen && (
        <animated.ul style={menuAnimation}>
          <li>
            <Link to="/" onClick={closeMenu}>HOME</Link>
          </li>
          <li>
            <Link to="/recipes" onClick={closeMenu}>FIND RECIPES</Link>
          </li>
          <li>
            <Link to="/your_recipes" onClick={closeMenu}>YOUR RECIPES</Link>
          </li>
          <li onClick={() => toggleSubMenu('info')} style={{ cursor: 'pointer' }}>
            INFO
          </li>
          <div
            style={{
              maxHeight: activeSubMenu === 'info' ? '200px' : '0px',
              overflow: 'hidden',
              transition: 'max-height 0.3s ease-out',
            }}
          >
            <div style={{ paddingLeft: '10px' }}>
              <li><Link to="/about" onClick={closeMenu}>ABOUT</Link></li>
              <li><Link to="/faq" onClick={closeMenu}>FAQ</Link></li>
              <li><Link to="/kitchen_tips" onClick={closeMenu}>KITCHEN TIPS</Link></li>
              <li><Link to="/contact" onClick={closeMenu}>CONTACT</Link></li>
            </div>
          </div>
          {isLoggedIn ? (
            <>
              <li><Link to="/account" onClick={closeMenu}>ACCOUNT</Link></li>
              {/* <li><Link to="/logout" onClick={closeMenu}>LOG OUT</Link></li> */}
            </>
          ) : (
            <>
              <li><Link to="/login" onClick={closeMenu}>LOG IN</Link></li>
              <li><Link to="/signup" onClick={closeMenu}>SIGN UP</Link></li>
            </>
          )}
        </animated.ul>
      )}
    </animated.header>
  );
}

export default MobileHeader;