import React, { useState } from 'react';

interface PasswordProtectionProps {
  onAuthenticate: (password: string) => boolean;
}

const PasswordProtection: React.FC<PasswordProtectionProps> = ({ onAuthenticate }) => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const isAuthenticated = onAuthenticate(password);
    if (!isAuthenticated) {
      setError('No chef, incorrect password. Please try again.');
    } else {
      setError('');
    }
  };

  return (
    <div className="password-protection-container-entry">
      <div className="password-protection-box">
        <img src="/shefmeme.png" alt="Chef Meme" className="logo"/>
        <h2>Enter Password</h2>
        <form onSubmit={handleSubmit}>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter password"
          />
          <button type="submit">Submit</button>
        </form>
        {error && <p className="error-message">{error}</p>}
      </div>
    </div>
  );
};

export default PasswordProtection;