import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signupUser } from '../services/api'; // adjust the import path as needed
import { useAuth } from '../context/AuthContext';

interface LoginProps {
  setIsLoggedIn: (loggedIn: boolean) => void;
}

const Login: React.FC<LoginProps> = ({ setIsLoggedIn }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [userName, setUserName] = useState('');
  const [isLogin, setIsLogin] = useState(true);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');


  const { login } = useAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setSuccessMessage(''); // Clear any previous success message
    try {
      if (isLogin) {
        // Login process
        const loginSuccess = await login(email, password);
        if (loginSuccess) {
          console.log('Login successful');
          setIsLoggedIn(true); // Update the shared state
          setSuccessMessage('You have successfully logged in'); // Set success message
          setTimeout(() => {
            navigate('/'); // Redirect to homepage after 2 seconds
          }, 2000);
        } else {
          console.log('Login failed');
          setError('Login failed. Please check your credentials.');
        }
      } else {
        // Signup process
        const response = await signupUser(email, password, userName, 'en');
        if (response.authentication_status) {
          setIsLogin(true);
          setError('Signup successful! Please log in.');
        } else {
          setError('Signup failed. Please try again.');
        }
      }
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message || 'Authentication failed. Please try again.');
      } else {
        setError('An unexpected error occurred. Please try again.');
      }
      console.error('Authentication error:', error);
    }
  };

  return (
    <div>
      <img src="/rat.svg" alt="Ratatouille NYC logo" className="logo-small"/>
      <h1 className="title">Ratatouille NYC</h1>
      <div className="password-protection-container">
        <img src="/gr_meme.png" alt="Logo" className="logo" />
        <div className="password-protection-box">
          <h2>{isLogin ? 'Login' : 'Sign Up'}</h2>
          {error && (
            <div className="error-message" style={{ color: 'red', marginBottom: '10px' }}>
              {error}
            </div>
          )}
          <form onSubmit={handleSubmit}>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Email"
              required
            />
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Password"
              required
            />
            {!isLogin && (
              <input
                type="text"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                placeholder="Chef Name"
                required
              />
            )}
            <button type="submit">{isLogin ? 'Login' : 'Sign Up'}</button>
          </form>
          <p>
            {isLogin ? "Don't have an account? " : "Already have an account? "}
            <a href="#" onClick={(e) => {
              e.preventDefault();
              setIsLogin(!isLogin);
              setError('');
            }}>
              {isLogin ? 'Sign Up' : 'Login'}
            </a>
          </p>
        </div>
      </div>
      {successMessage && (
        <div className="success-message" style={{ color: 'green', marginBottom: '10px' }}>
          {successMessage}
        </div>
      )}
    </div>
  );
};

export default Login;