import React from 'react';

const FAQ: React.FC = () => {
    return (
        <div className="about-container">
            <div className="contact-header">
                <img src="/rat.svg" alt="Ratatouille NYC logo" className="logo-small" />
                <h1 className="title">Ratatouille NYC</h1>
            </div>

            <p className="description"> We hope you can find the answers you're looking for, Chef. Please visit our contact page if we're missing something.</p>
            <div className="tomato-heart-svg-container">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="80" height="96">
                        <g transform="rotate(30, 50, 50)">
                            <ellipse cx="50" cy="55" rx="30" ry="40" fill="#4B5D2E"/>
                            <ellipse cx="40" cy="45" rx="12" ry="15" fill="#6B8242"/>
                            <ellipse cx="50" cy="25" rx="5" ry="4" fill="#D62828"/>
                        </g>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="80" height="96">
                        <g transform="rotate(30, 50, 50)">
                            <ellipse cx="50" cy="55" rx="30" ry="40" fill="#4B5D2E"/>
                            <ellipse cx="40" cy="45" rx="12" ry="15" fill="#6B8242"/>
                            <ellipse cx="50" cy="25" rx="5" ry="4" fill="#D62828"/>
                        </g>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="80" height="96">
                        <g transform="rotate(30, 50, 50)">
                            <ellipse cx="50" cy="55" rx="30" ry="40" fill="#4B5D2E"/>
                            <ellipse cx="40" cy="45" rx="12" ry="15" fill="#6B8242"/>
                            <ellipse cx="50" cy="25" rx="5" ry="4" fill="#D62828"/>
                        </g>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="80" height="96">
                        <g transform="rotate(30, 50, 50)">
                            <ellipse cx="50" cy="55" rx="30" ry="40" fill="#4B5D2E"/>
                            <ellipse cx="40" cy="45" rx="12" ry="15" fill="#6B8242"/>
                            <ellipse cx="50" cy="25" rx="5" ry="4" fill="#D62828"/>
                        </g>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="80" height="96">
                        <g transform="rotate(30, 50, 50)">
                            <ellipse cx="50" cy="55" rx="30" ry="40" fill="#4B5D2E"/>
                            <ellipse cx="40" cy="45" rx="12" ry="15" fill="#6B8242"/>
                            <ellipse cx="50" cy="25" rx="5" ry="4" fill="#D62828"/>
                        </g>
                    </svg>
            </div>


            <div className="faq-content">
                <p className="card-title">Frequently Asked Questions</p>
                
                {/* FAQ Item 1 */}
                <div className="todays-menu-divider"></div>
                <p className="card-title">How much food have you saved?</p>
                <p className="expanded-content">
                    We have generated 350 recipes total so far. If we assume that users cooked 10% of those recipes, we can calculate that approximately ~x kg of food was consumed and not ending up in a landfill. [WE SHOULD PROBABLY FIGURE A WAY TO CALC THIS LIVE]
                </p>

                {/* FAQ Item 2 */}
                <div className="todays-menu-divider"></div>
                <p className="card-title">How does it work?</p>
                <p className="expanded-content">
                    Our system combines pattern recognition and culinary logic to analyze your available ingredients and instantly generate creative, practical recipes tailored to what you have in your kitchen. We match cooking techniques with ingredient combinations to suggest dishes that help you reduce waste while making something delicious.
                </p>

                {/* FAQ Item 3 */}
                <div className="todays-menu-divider"></div>
                <p className="card-title">How do you make money?</p>
                <p className="expanded-content">
                    We currently operate as a free service. Our operating costs are ~ $400 a month. Please consider making a donation so we don't have to run clunky ads.
                </p>

                {/* FAQ Item 5 */}
                <div className="todays-menu-divider"></div>
                <p className="card-title">Does this work with dietary restrictions?</p>
                <p className="expanded-content">
                    Yes, you can specify vegetarian, vegan, gluten-free, and dairy-free preferences when generating recipes. However, since our AI generates recipes in real-time, we recommend double-checking ingredients and substitutions if you have serious dietary restrictions or allergies. While we strive for accuracy, we cannot guarantee ingredient cross-contamination risk levels or absolute compliance with specific dietary restrictions—please use your best judgment and consult medical professionals if you have specific dietary needs.
                </p>

                {/* FAQ Item 4 */}
                <div className="todays-menu-divider"></div>
                <p className="card-title">Where do calorie counts come from?</p>
                <p className="expanded-content">
                Calorie counts are rough approximations generated by our AI system. While we strive for accuracy, Ratatouille is focused on reducing food waste rather than precise nutritional tracking. If you're following a specific diet or need exact calorie counts, please consult with a nutritionist or use dedicated nutrition apps.
                </p>

                {/* FAQ Item 5 */}
                <div className="todays-menu-divider"></div>
                <p className="card-title">Is this app unique to New York City?</p>
                <p className="expanded-content">
                    No, while we started in NYC and love our city's unique food culture, our recipe suggestions work anywhere. We just happen to have a soft spot for fire escapes and strong opinions about what makes a good bagel. Anyone looking to reduce food waste and make the most of their ingredients can use Ratatouille.
                </p>


                {/* FAQ Item 6 */}
                <div className="todays-menu-divider"></div>
                <p className="card-title">Can I contribute my own recipes?</p>
                <p className="expanded-content">
                    Yes, we welcome user contributions! You can submit your recipes through our website, and they may be featured in our collection or via social media.
                </p>


                {/* FAQ Item 10 */}
                <div className="todays-menu-divider"></div>
                <p className="card-title">How can I support Ratatouille?</p>
                <p className="expanded-content">
                    Use the app, spread the word. If you find it helpful, consider making a small donation to help cover our operating costs. The more people we reach, the more food waste we can reduce together.
                </p>
                <div className="todays-menu-divider"></div>

            </div>
        </div>
    );
};

export default FAQ;