import React from 'react';

const KitchenTips: React.FC = () => {
    return (
        <div className="about-container">
            <div className="contact-header">
                <img src="/rat.svg" alt="Ratatouille NYC logo" className="logo-small" />
                <h1 className="title">Ratatouille NYC</h1>
            </div>

            <p className="description">Tips to help you make the most out of your kitchen scraps. </p>
            {/* <div className="tomato-heart-svg-container">
                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" >
                    <g>
                        <path style={{ fill: "#583E68" }} d="M50.841,18.962c0.004,0.003,0.008,0.007,0.012,0.01l-0.022-0.015
                            C50.835,18.958,50.838,18.96,50.841,18.962c-1.072-0.759-1.505-1.993-0.965-2.762c-0.542,0.772-1.858,0.783-2.939,0.025
                            c-1.081-0.759-1.519-2-0.977-2.772c-0.542,0.772-1.858,0.783-2.939,0.025c-1.081-0.759-1.518-2-0.977-2.772
                            c-0.5,0.713-1.658,0.769-2.684,0.178c-1.531,1.114-3.23,2.625-5.094,4.679c0,0-8.066,7.537-19.445,9.612
                            c-1.052,0.192-2.096,0.447-3.077,0.873c-0.44,0.191-1.418,0.702-1.418,0.702C3.63,30.453,0.055,38.358,2.156,46.071
                            c1.886,6.926,7.792,11.667,14.517,12.413c3.086,0.342,6.191-0.331,9.001-1.651c6.256-2.938,19.014-11.419,24.29-33.11
                            c0,0,0.796-1.966,1.364-4.514C51.161,19.148,50.998,19.07,50.841,18.962z"/>
                        <path style={{ fill: "#6A3311" }} d="M50.555,7.66c-0.552,0-1-0.447-1-1s0.448-1,1-1c1.922,0,3.506-0.587,4.134-1.531
                            c0.451-0.679,0.438-1.601-0.041-2.741c-0.213-0.509,0.026-1.095,0.536-1.309c0.511-0.216,1.095,0.026,1.309,0.535
                            c0.938,2.237,0.443,3.748-0.138,4.622C55.346,6.754,53.178,7.66,50.555,7.66z"/>
                        <path style={{ fill: "#659C35" }} d="M39.085,10.715c-0.994-0.698-1.26-2.034-0.593-2.984c0.667-0.951,2.014-1.156,3.008-0.458
                            c2.134-3.042,5.978-2.773,9.16-0.54c3.182,2.233,4.62,5.669,2.486,8.711c0.994,0.698,1.26,2.034,0.593,2.984
                            s-1.913,1.226-2.907,0.529l0.022,0.015c-1.081-0.759-1.519-2-0.977-2.772c-0.542,0.772-1.858,0.783-2.939,0.025
                            c-1.081-0.759-1.519-2-0.977-2.772c-0.542,0.772-1.858,0.783-2.939,0.025c-1.081-0.759-1.519-2-0.977-2.772
                            c-0.542,0.772-1.858,0.783-2.939,0.025"/>
                        <path style={{ fill: "#6F58A8" }} d="M9.302,50.316c-0.337,0-0.667-0.171-0.855-0.479c-2.383-3.912-2.656-9.118-0.711-13.588
                            c0.22-0.507,0.81-0.74,1.316-0.519c0.506,0.221,0.738,0.81,0.518,1.315c-1.689,3.883-1.464,8.386,0.585,11.75
                            c0.287,0.472,0.138,1.087-0.333,1.374C9.659,50.269,9.479,50.316,9.302,50.316z"/>
                        <path style={{ fill: "#6F58A8" }} d="M10.646,34.449c-0.231,0-0.463-0.08-0.652-0.242c-0.418-0.361-0.465-0.992-0.104-1.41
                            c1.227-1.424,2.77-2.539,4.462-3.228c0.511-0.207,1.095,0.039,1.303,0.551c0.208,0.511-0.038,1.095-0.55,1.303
                            c-1.4,0.568-2.68,1.495-3.7,2.679C11.206,34.331,10.926,34.449,10.646,34.449z"/>
                    </g>
                </svg>
            </div> */}
            <div className="tomato-heart-svg-container">

                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="96" height="60">
                <g transform="rotate(25, 50, 50)">  
                    <path d="M50 5 C35 20, 65 20, 50 5" fill="#2E8B57" stroke="#2E8B57" stroke-width="2"/>
                    <path d="M45 10 C30 25, 60 25, 45 10" fill="#2E8B57" stroke="#2E8B57" stroke-width="2"/>
                    <path d="M55 10 C40 25, 70 25, 55 10" fill="#2E8B57" stroke="#2E8B57" stroke-width="2"/>
                    
                    <path d="M50 80 L38 25 Q50 15 62 25 Z" fill="#FF7F00" stroke="#FF6B00" stroke-width="2"/>
                    
                    <path d="M40 35 L60 35" stroke="#FF6B00" stroke-width="1.5"/>
                    <path d="M43 45 L57 45" stroke="#FF6B00" stroke-width="1.5"/>
                    <path d="M45 55 L55 55" stroke="#FF6B00" stroke-width="1.5"/>
                    <path d="M47 65 L53 65" stroke="#FF6B00" stroke-width="1.5"/>
                </g>
                </svg>
                        
                        

                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="96" height="60">
                <g transform="rotate(25, 50, 50)">
                    <path d="M50 5 C35 20, 65 20, 50 5" fill="#2E8B57" stroke="#2E8B57" stroke-width="2"/>
                    <path d="M45 10 C30 25, 60 25, 45 10" fill="#2E8B57" stroke="#2E8B57" stroke-width="2"/>
                    <path d="M55 10 C40 25, 70 25, 55 10" fill="#2E8B57" stroke="#2E8B57" stroke-width="2"/>
                    
                    <path d="M50 80 L38 25 Q50 15 62 25 Z" fill="#FF7F00" stroke="#FF6B00" stroke-width="2"/>
                    
                    <path d="M40 35 L60 35" stroke="#FF6B00" stroke-width="1.5"/>
                    <path d="M43 45 L57 45" stroke="#FF6B00" stroke-width="1.5"/>
                    <path d="M45 55 L55 55" stroke="#FF6B00" stroke-width="1.5"/>
                    <path d="M47 65 L53 65" stroke="#FF6B00" stroke-width="1.5"/>
                </g>
                </svg>



                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="96" height="60">
                <g transform="rotate(25, 50, 50)">
                    <path d="M50 5 C35 20, 65 20, 50 5" fill="#2E8B57" stroke="#2E8B57" stroke-width="2"/>
                    <path d="M45 10 C30 25, 60 25, 45 10" fill="#2E8B57" stroke="#2E8B57" stroke-width="2"/>
                    <path d="M55 10 C40 25, 70 25, 55 10" fill="#2E8B57" stroke="#2E8B57" stroke-width="2"/>
                    
                    <path d="M50 80 L38 25 Q50 15 62 25 Z" fill="#FF7F00" stroke="#FF6B00" stroke-width="2"/>
                    
                    <path d="M40 35 L60 35" stroke="#FF6B00" stroke-width="1.5"/>
                    <path d="M43 45 L57 45" stroke="#FF6B00" stroke-width="1.5"/>
                    <path d="M45 55 L55 55" stroke="#FF6B00" stroke-width="1.5"/>
                    <path d="M47 65 L53 65" stroke="#FF6B00" stroke-width="1.5"/>
                </g>
                </svg>



                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="96" height="60">
                <g transform="rotate(25, 50, 50)">
                    <path d="M50 5 C35 20, 65 20, 50 5" fill="#2E8B57" stroke="#2E8B57" stroke-width="2"/>
                    <path d="M45 10 C30 25, 60 25, 45 10" fill="#2E8B57" stroke="#2E8B57" stroke-width="2"/>
                    <path d="M55 10 C40 25, 70 25, 55 10" fill="#2E8B57" stroke="#2E8B57" stroke-width="2"/>
                    
                    <path d="M50 80 L38 25 Q50 15 62 25 Z" fill="#FF7F00" stroke="#FF6B00" stroke-width="2"/>
                    
                    <path d="M40 35 L60 35" stroke="#FF6B00" stroke-width="1.5"/>
                    <path d="M43 45 L57 45" stroke="#FF6B00" stroke-width="1.5"/>
                    <path d="M45 55 L55 55" stroke="#FF6B00" stroke-width="1.5"/>
                    <path d="M47 65 L53 65" stroke="#FF6B00" stroke-width="1.5"/>
                </g>
                </svg>



                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="96" height="60">
                <g transform="rotate(25, 50, 50)">
                    <path d="M50 5 C35 20, 65 20, 50 5" fill="#2E8B57" stroke="#2E8B57" stroke-width="2"/>
                    <path d="M45 10 C30 25, 60 25, 45 10" fill="#2E8B57" stroke="#2E8B57" stroke-width="2"/>
                    <path d="M55 10 C40 25, 70 25, 55 10" fill="#2E8B57" stroke="#2E8B57" stroke-width="2"/>
                    
                    <path d="M50 80 L38 25 Q50 15 62 25 Z" fill="#FF7F00" stroke="#FF6B00" stroke-width="2"/>
                    
                    <path d="M40 35 L60 35" stroke="#FF6B00" stroke-width="1.5"/>
                    <path d="M43 45 L57 45" stroke="#FF6B00" stroke-width="1.5"/>
                    <path d="M45 55 L55 55" stroke="#FF6B00" stroke-width="1.5"/>
                    <path d="M47 65 L53 65" stroke="#FF6B00" stroke-width="1.5"/>
                </g>
                </svg>



                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" width="96" height="60">
                <g transform="rotate(25, 50, 50)">
                    <path d="M50 5 C35 20, 65 20, 50 5" fill="#2E8B57" stroke="#2E8B57" stroke-width="2"/>
                    <path d="M45 10 C30 25, 60 25, 45 10" fill="#2E8B57" stroke="#2E8B57" stroke-width="2"/>
                    <path d="M55 10 C40 25, 70 25, 55 10" fill="#2E8B57" stroke="#2E8B57" stroke-width="2"/>
                    
                    <path d="M50 80 L38 25 Q50 15 62 25 Z" fill="#FF7F00" stroke="#FF6B00" stroke-width="2"/>
                    
                    <path d="M40 35 L60 35" stroke="#FF6B00" stroke-width="1.5"/>
                    <path d="M43 45 L57 45" stroke="#FF6B00" stroke-width="1.5"/>
                    <path d="M45 55 L55 55" stroke="#FF6B00" stroke-width="1.5"/>
                    <path d="M47 65 L53 65" stroke="#FF6B00" stroke-width="1.5"/>
                </g>
                </svg>



                

            </div>

            <div className="faq-content">
                <p className="card-title">Kitchen Tips</p>
                <p className="expanded-content">Discover innovative ways to breathe new life into ingredients that might otherwise go to waste in your kitchen. From wilting vegetables to stale bread, these creative techniques help you transform seemingly spent ingredients into delicious new dishes and useful cooking elements. These practical tips not only reduce food waste and stretch your grocery budget but also unlock hidden culinary potential in items you might typically discard. Each transformation offers a simple yet effective way to maximize your ingredients' usefulness while maintaining the joy of cooking and creating new kitchen possibilities.</p>
                <div className="todays-menu-divider"></div>

                <p className="card-title">Transform Tired Vegetables Into Soup Base</p>
                <p className="expanded-content">
                    Collect any aging vegetables from your fridge like carrots, celery, onions, and peppers. Roast them together with olive oil and herbs at 400°F until they're caramelized and fragrant. Blend with stock to create a versatile soup base that can be frozen and used later.
                </p>

                <div className="todays-menu-divider"></div>
                <p className="card-title">Convert Aging Fruit Into Smoothie Starters</p>
                <p className="expanded-content">
                    Save overripe or bruised fruits by cutting away any bad spots and freezing the good parts in small portions. These frozen fruit pieces become perfect smoothie starters when blended with fresh ingredients. Add liquid and protein of your choice for an instant healthy drink.
                </p>

                <div className="todays-menu-divider"></div>
                <p className="card-title">Turn Day-Old Bread Into French Toast</p>
                <p className="expanded-content">
                Give stale bread new life as a decadent breakfast treat. Soak thick slices in a mixture of eggs, milk, vanilla, and cinnamon, then cook on a buttered griddle until golden brown. This classic "pain perdu" (lost bread) transforms yesterday's bread into today's breakfast masterpiece.
                </p>

                <div className="todays-menu-divider"></div>
                <p className="card-title">Turn Herbs Into Frozen Flavor Cubes</p>
                <p className="expanded-content">
                    Collect herbs that are starting to wilt but aren't spoiled yet. Blend them with olive oil and freeze in ice cube trays for instant flavor bombs. These cubes can be dropped into soups, stews, or pasta dishes for months to come.
                </p>

                <div className="todays-menu-divider"></div>
                <p className="card-title">Create All-Purpose Stock From Scraps</p>
                <p className="expanded-content">
                    Save vegetable trimmings, peels, and ends in a freezer bag until it's full. Simmer these scraps with water and seasonings to make a flavorful stock base. Strain and freeze in portions for future cooking.
                </p>

                <div className="todays-menu-divider"></div>
                <p className="card-title">Transform Stale Bread Into Crispy Croutons</p>
                <p className="expanded-content">
                Never throw out stale bread - it's perfect for homemade croutons or breadcrumbs. Cut bread into even cubes, toss with olive oil, garlic, and seasonings, then bake at 375°F until golden brown and crunchy. Store in an airtight container for up to two weeks, or pulse into breadcrumbs and freeze for months.
                </p>

                <div className="todays-menu-divider"></div>
                <p className="card-title">Turn Root Vegetable Tops Into Pesto</p>
                <p className="expanded-content">
                    Save the leafy tops from carrots, beets, and radishes that usually get discarded. Blend them with olive oil, garlic, and nuts to create a nutritious alternative to traditional pesto. This reduces waste while adding variety to your meals.
                </p>

                <div className="todays-menu-divider"></div>
                <p className="card-title">Preserve Hard Cheese Rinds As Flavor Enhancers</p>
                <p className="expanded-content">
                    Save Parmesan and other hard cheese rinds in the freezer instead of throwing them away. Add these rinds to simmering soups, stocks, and sauces to infuse them with rich, savory flavor. Remove before serving.
                </p>

                <div className="todays-menu-divider"></div>
                <p className="card-title">Convert Coffee Grounds Into Garden Gold</p>
                <p className="expanded-content">
                    Don't toss those used coffee grounds - they're packed with nutrients that plants love. Sprinkle them directly into your garden soil or add to your compost bin. Coffee grounds help improve soil structure, add nitrogen, and can even deter certain garden pests naturally.
                </p>
                <div className="todays-menu-divider"></div>

            </div>
        </div>
    );
};

export default KitchenTips;