import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';
import '../styles/index.css';


interface IngredientInputProps {
  onSubmit: (ingredients: string[], cuisine: string, dietaryRestrictions: string[]) => void;
}

const IngredientInput: React.FC<IngredientInputProps> = ({ onSubmit }) => {
  const [ingredients, setIngredients] = useState<string[]>(Array(6).fill(''));
  const [cuisine, setCuisine] = useState<string>('');
  const [dietaryRestrictions, setDietaryRestrictions] = useState<string[]>([]);
  const [isExpanded, setIsExpanded] = useState(false);

  const handleIngredientChange = (index: number, value: string) => {
    const newIngredients = [...ingredients];
    newIngredients[index] = value;
    setIngredients(newIngredients);
  };

  const handleAddIngredient = () => {
    setIngredients([...ingredients, '']);
  };

  const handleCuisineChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCuisine(e.target.value);
  };

  const handleDietaryRestrictionChange = (restriction: string) => {
    setDietaryRestrictions(prev =>
      prev.includes(restriction)
        ? prev.filter(r => r !== restriction)
        : [...prev, restriction]
    );
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const nonEmptyIngredients = ingredients.filter(ing => ing.trim() !== '');
    console.log("Submitting ingredients from IngredientInput:", nonEmptyIngredients);
    console.log("Cuisine:", cuisine);
    console.log("Dietary Restrictions:", dietaryRestrictions);
    onSubmit(nonEmptyIngredients, cuisine, dietaryRestrictions);
  };

  const cuisineOptions = [
    { value: 'italian', label: '🇮🇹 Italian' },
    { value: 'chinese', label: '🇨🇳 Chinese' },
    { value: 'japanese', label: '🇯🇵 Japanese' },
    { value: 'indian', label: '🇮🇳 Indian' },
    { value: 'mexican', label: '🇲🇽 Mexican' },
    { value: 'french', label: '🇫🇷 French' },
    { value: 'thai', label: '🇹🇭 Thai' },
    { value: 'greek', label: '🇬🇷 Greek' },
    { value: 'spanish', label: '🇪🇸 Spanish' },
    { value: 'american', label: '🇺🇸 American' },
  ];

  const dietaryRestrictionOptions = ['Vegan', 'Vegetarian', 'Gluten-Free', 'Dairy-Free'];

  const animationProps = useSpring({
    height: isExpanded ? 'auto' : 0,
    opacity: isExpanded ? 1 : 0,
    overflow: 'hidden',
  });

  return (
    <form onSubmit={handleSubmit} className="ingredients-container">
    <div className="ingredient-input">
      {ingredients.map((ingredient, index) => (
        <div key={index} className="ingredient-cell">
          <input
            type="text"
            value={ingredient}
            onChange={(e) => handleIngredientChange(index, e.target.value)}
            placeholder={`Ingredient ${index + 1}`}
          />
        </div>
      ))}
    </div>
    <div className="expand-button-container">
      <div className="add-ingredient" onClick={handleAddIngredient}>+</div>
      <div className="expand-options" onClick={() => setIsExpanded(!isExpanded)}>~</div>
    </div>

      <animated.div style={animationProps}>
        <div className="cuisine-select">
          <label htmlFor="cuisine">Cuisine:</label>
          <div className="select-wrapper">
            <select id="cuisine" value={cuisine} onChange={handleCuisineChange}>
              <option value="">Select a cuisine</option>
              {cuisineOptions.map(option => (
                <option key={option.value} value={option.value}>{option.label}</option>
              ))}
            </select>
          </div>
        </div>
        <div className="dietary-restrictions">
          <p>Dietary Restrictions:</p>
          <div className="dietary-restrictions-grid">
            {dietaryRestrictionOptions.map(restriction => (
              <label key={restriction}>
                <input
                  type="checkbox"
                  checked={dietaryRestrictions.includes(restriction)}
                  onChange={() => handleDietaryRestrictionChange(restriction)}
                />
                {restriction}
              </label>
            ))}
          </div>
        </div>
      </animated.div>

      <button type="submit" className="recipe-submit-button">Get Recipes</button>
    </form>
  );
};

export default IngredientInput;